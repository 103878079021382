import {targetingCookiesAllowed} from '../helpers/cookie';
import {getPPID} from '../helpers/cookie';

type AdRefreshConfig = {
    [key: string]: googletag.Slot
}

type AdTargetingConfig = {
    [key: string]: string
}

interface BidSlot {
    slotID: string;
    slotName: string;
    sizes: [number, number][];
}

const slotsToRefresh: AdRefreshConfig = {};
let refreshStarted = false;

const lazyLoadConfig = {
    // Fetch slots within 5 viewports.
    fetchMarginPercent: 500,
    // Render slots within 2 viewports.
    renderMarginPercent: 200,
    // Double the above values on mobile, where viewports are smaller
    // and users tend to scroll faster.
    mobileScaling: 2.0,
};

function shouldDisplay(advert: AdvertSlot) {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    return (typeof advert.min === 'undefined' || width >= advert.min) &&
        (typeof advert.max === 'undefined' || width <= advert.max);
}

function enableAdRefresh(event: googletag.events.SlotRenderEndedEvent) {
    const slotElementId = event.slot.getSlotElementId();
    const adConfig = window.advertSlots.find((advert) => {
        return slotElementId === advert.id;
    });

    if (!adConfig) {
        return;
    }

    const refreshEnabled = typeof adConfig.refresh !== 'undefined' && adConfig.refresh > 0;

    if (!refreshEnabled) {
        return;
    }

    slotsToRefresh[slotElementId] = event.slot;

    if (!refreshStarted) {
        setTimeout(function() {
            window.googletag.pubads().refresh(Object.values(slotsToRefresh));
        }, 25000);
        refreshStarted = true;
    }
}

const ADS_PERSONALIZED = 'personalized';
const ADS_NON_PERSONALIZED = 'non-personalized';
const ADS_LIMITED = 'limited';

function consentLoadedListener(tcData: any, listenerSuccess: boolean) {
    if (!listenerSuccess || !(tcData.eventStatus === 'tcloaded' || tcData.eventStatus === 'useractioncomplete')) {
        return;
    }

    // GDPR DOES NOT APPLY
    // Insert adsbygoogle.js onto the page.
    const gdprApplies = tcData.gdprApplies;

    // GDPR DOES APPLY
    // Purpose 1 refers to the storage and/or access of information on a device.
    const hasDeviceStorageAndAccessConsent = tcData.purpose.consents[1] || false;
    const hasCreatePersonalizedAdsProfile = tcData.purpose.consents[3] || false;
    const hasSelectPersonalizedAds = tcData.purpose.consents[4] || false;

    // GLOBAL VENDOR LIST - https://iabeurope.eu/vendor-list-tcf-v2-0/
    // CHECK FOR GOOGLE ADVERTISING PRODUCTS CONSENT. (IAB Vendor ID 755)
    const hasGoogleAdvertisingProductsConsent = tcData.vendor.consents[755] || false;

    const canSelectNonPersonalizedAds = hasDeviceStorageAndAccessConsent && hasGoogleAdvertisingProductsConsent;

    const canSelectPersonalizedAds = canSelectNonPersonalizedAds &&
        hasCreatePersonalizedAdsProfile &&
        hasSelectPersonalizedAds;

    adverts.loadLytics();
    if (!gdprApplies || canSelectPersonalizedAds) {
        setupAdSlots(ADS_PERSONALIZED);
    } else if (canSelectNonPersonalizedAds) {
        setupAdSlots(ADS_NON_PERSONALIZED);
    } else {
        setupAdSlots(ADS_LIMITED);
    }
}

function setupAdSlots(adType: string) {
    if (!window.advertSlots.length) {
        return;
    }
    window.googletag = window.googletag || {cmd: []};
    window.googletag.cmd = window.googletag.cmd || [];
    window.advertSlots = window.advertSlots || [];
    window.googletag.cmd.push(function() {
        const utms = `${window.utmSource || ''} ${window.utmMedium || ''} ${window.utmCampaign || ''}`;
        window.googletag.pubads().setTargeting('utms', utms);

        window.advertSlots.forEach((advert: AdvertSlot) => {
            if (!shouldDisplay(advert) || advert.deferred === 'true') {
                return;
            }
            advert.dfpObj = window.googletag
                .defineSlot(advert.slot, advert.dimensions, advert.id)
                .addService(window.googletag.pubads())
                .setTargeting('cat', advert.cat || '')
                .setTargeting('make', advert.make || '')
                .setTargeting('model', advert.model || '')
                .setTargeting('body', advert.body || '')
                .setTargeting('vehicleType', advert.vehicleType || '')
                .setTargeting('loggedIn', advert.loggedIn || '');
        });

        window.googletag.pubads().addEventListener('slotRenderEnded', enableAdRefresh);
        window.googletag.pubads().enableSingleRequest();
        window.googletag.pubads().collapseEmptyDivs();

        if (adType === ADS_NON_PERSONALIZED) {
            window.googletag.pubads().setRequestNonPersonalizedAds(1);
        }

        if (adType === ADS_LIMITED) {
            window.googletag.pubads().setPrivacySettings({
                limitedAds: true,
            });
        }
    });

    adverts._connectLyticsTargeting();
}

const adverts = {
    init() {
        // Run this in an interval (every 0.1s) just in case we are still waiting for consent
        let cnt = 0;
        const consentSetInterval = setInterval(function() {
            cnt += 1;

            // Bail if we have not gotten a consent response after 60 seconds.
            if (cnt === 600) {
                clearInterval(consentSetInterval);
            }

            // Check if window.__tcfapi has been set
            if (typeof window.__tcfapi !== 'undefined') {
                clearInterval(consentSetInterval);
                window.__tcfapi('addEventListener', 2, consentLoadedListener);
            }
            cnt++;
        }, 100);
    },
    loadLytics() {
        /* eslint-disable */
        if (targetingCookiesAllowed()) {
            // load lytics v3
            // @ts-ignore
            !function(){"use strict";var o=window.jstag||(window.jstag={}),r=[];function n(e){o[e]=function(){for(var n=arguments.length,t=new Array(n),i=0;i<n;i++)t[i]=arguments[i];r.push([e,t])}}n("send"),n("mock"),n("identify"),n("pageView"),n("unblock"),n("getid"),n("setid"),n("loadEntity"),n("getEntity"),n("on"),n("once"),n("call"),o.loadScript=function(n,t,i){var e=document.createElement("script");e.async=!0,e.src=n,e.onload=t,e.onerror=i;var o=document.getElementsByTagName("script")[0],r=o&&o.parentNode||document.head||document.body,c=o||r.lastChild;return null!=c?r.insertBefore(e,c):r.appendChild(e),this},o.init=function n(t){return this.config=t,this.loadScript(t.src,function(){if(o.init===n)throw new Error("Load error!");o.init(o.config),function(){for(var n=0;n<r.length;n++){var t=r[n][0],i=r[n][1];o[t].apply(o,i)}r=void 0}()}),this}}();

            // initialise lytics
            window.jstag.init({
                src: '//c.lytics.io/api/tag/07fff5da924b3993e5b668467ac1d2d5/latest.min.js',
            });
        }
    },
    _connectLyticsTargeting() {
        const currentUrl = window.location.href;
        // Load Lytics and enable DFP user targeting only if targeted Cookie consent was given
        if (targetingCookiesAllowed()) {
            // disable google ads init
            // as Lytics set DFP into disableInitialLoad, we need to manually refresh with
            // window.googletag.pubads().refresh(); vs adverts.display();
            // below ensures
            window.googletag.cmd.push(function() {
                window.googletag.pubads().disableInitialLoad();
            });

            const userLoggedIn = window.userLoggedIn || false;

            if (userLoggedIn) {
                // load google ads once lytics entity was loaded
                window.jstag.call('entityReady', function(event: any, entity: any) {
                    window.googletag.cmd.push(async function () {
                        if (event === 'entity.loaded') {
                            window.googletag.pubads().setTargeting('LyticsSegments', entity.data.user.segments);
                        }
                        window.googletag.pubads().enableLazyLoad(lazyLoadConfig);
                        window.googletag.pubads().setPublisherProvidedId(await getPPID());
                        window.googletag.enableServices();

                        currentUrl.includes('/car-quiz') ? adverts.apsBidding() : window.googletag.pubads().refresh();
                    });
                });
            } else {
                window.googletag.cmd.push(async function () {
                    window.googletag.pubads().enableLazyLoad(lazyLoadConfig);
                    window.googletag.pubads().setPublisherProvidedId(await getPPID());
                    window.googletag.enableServices();

                    currentUrl.includes('/car-quiz') ? adverts.apsBidding() : window.googletag.pubads().refresh();
                });
            }
        } else {
            window.jstag = {
                send() {
                    // placeholder when Lytics disabled;
                    console.warn('Lytics is currently disabled by targeting Cookie consent');
                },
            };
            window.googletag.cmd.push(function() {
                window.googletag.pubads().enableLazyLoad(lazyLoadConfig);
                window.googletag.enableServices();

                if (currentUrl.includes('/car-quiz')) {
                    adverts.apsBidding();
                }
            });
            if (!currentUrl.includes('/car-quiz')) {
                adverts.display();
            }
        }
    },
    display() {
        window.googletag.cmd.push(function() {
            window.advertSlots.forEach((advert: AdvertSlot) => {
                if (advert.displayed === true || !shouldDisplay(advert) || advert.deferred === 'true') {
                    return;
                }
                window.googletag.display(advert.id);
                advert.displayed = true;
            });
        });
    },
    mapAdvertSlotToBid(slot: AdvertSlot): BidSlot | null {
        if (slot.dimensions[0] === 1 && slot.dimensions[1] === 1) {
            return null;  // ignore 1x1 size
        }
        return {
            slotID: slot.id,
            slotName: slot.slot,
            sizes: [slot.dimensions]
        };
    },
    apsBidding() {
        // @ts-ignore
        !function(a9,a,p,s,t,A,g){if(a[a9])return;function q(c,r){a[a9]._Q.push([c,r])}a[a9]={init:function(){q("i",arguments)},fetchBids:function(){q("f",arguments)},setDisplayBids:function(){},targetingKeys:function(){return[]},_Q:[]};A=p.createElement(s);A.async=!0;A.src=t;g=p.getElementsByTagName(s)[0];g.parentNode.insertBefore(A,g)}("apstag",window,document,"script","//c.amazon-adsystem.com/aax2/apstag.js");

        window.apstag.init({
            pubID: '1667f0b4-249e-46be-8481-ef473a1e4ea2',
            adServer: 'googletag'
        });

        // @ts-ignore
        window.apstag.fetchBids({
            slots: window.advertSlots.map(this.mapAdvertSlotToBid).filter((slot): slot is BidSlot => slot !== null),
            timeout: 2e3
        }, // @ts-ignore
            function(bids) {
            window.googletag.cmd.push(function(){
                window.apstag.setDisplayBids();
                window.googletag.pubads().refresh();
            });
        });
    },
    retargetAdverts(tags: AdTargetingConfig) {
        if (!window.advertSlots) {
            return;
        }

        const dfpToRefresh = window.advertSlots
            .filter((item) => item.dfpObj !== undefined)
            .map((item) => item.dfpObj);

        dfpToRefresh.forEach((dfpSlot) => {
            const setTargeting = dfpSlot.getTargetingKeys();
            Object.keys(tags).forEach((index) => {
                if (setTargeting.includes(index)) {
                    dfpSlot.clearTargeting(index);
                }
                if (tags[index] && tags[index] !== '-1') {
                    dfpSlot.setTargeting(index, tags[index]);
                }
            });
        });

        if (window.googletag && window.googletag.pubadsReady) {
            window.googletag.pubads().refresh(dfpToRefresh);
        }
    },
};

export default adverts;
