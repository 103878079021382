function getCookie(cname: string) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

function setCookie(cname: string, cvalue: string, exdays: number): void {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + encodeURIComponent(cvalue) + ';' + expires + ';path=/';
}

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0; const v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

function generatePPID(): string {
    return uuidv4();
}

async function simulateHash(input: string): Promise<string> {
    if (window.location.protocol === 'https:' && window.crypto && crypto.subtle) {
        try {
            const encoder = new TextEncoder();
            const data = encoder.encode(input);
            const hashBuffer = await crypto.subtle.digest('SHA-256', data);
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
        } catch (error) {
            console.error('Error hashing input:', error);
            return generatePPID(); // fallback to generatePPID on error
        }
    } else {
        // fallback to generatePPID when not HTTPS or crypto.subtle is unavailable
        return generatePPID();
    }
}

export const getPPID = async (): Promise<string> => {
    let cookiePpid = getCookie('regit_ppid');
    if (!cookiePpid) {
        const cookieUserid = getCookie('regit_user_id');
        if (cookieUserid) {
            cookiePpid = await simulateHash(cookieUserid);
        } else {
            cookiePpid = generatePPID();
        }
        setPPID(cookiePpid, 365);
    }
    return cookiePpid;
};

export const setPPID = (ppid: string, duration: number): void => {
    setCookie('regit_ppid', ppid, duration);
};

export const targetingCookiesAllowed = function() {
    const cookieOnetrust = getCookie('OptanonConsent');

    // Targeting Approved: group 4.
    const targetingConsentGranted = cookieOnetrust.match(/C0004:1/g);
    return !!targetingConsentGranted;
};

export const performanceCookiesAllowed = function() {
    const cookieOnetrust = getCookie('OptanonConsent');

    // Targeting Approved: group 2.
    const targetingConsentGranted = cookieOnetrust.match(/C0002:1/g);
    return !!targetingConsentGranted;
};

export const functionalCookiesAllowed = function() {
    const cookieOnetrust = getCookie('OptanonConsent');

    // Targeting Approved: group 3.
    const targetingConsentGranted = cookieOnetrust.match(/C0003:1/g);
    return !!targetingConsentGranted;
};
